import { Link } from 'react-router-dom'
import { ButtonGroup, Button } from '@mui/material'

function Home (props) {
  //console.log(props)
  return (<>
    <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
      {/* <Button variant="outlined">
        <Link to="/az" className="btn">old AZ .. Eingabe und Verwaltung der Officezeiten</Link>
      </Button> */}
      <Button variant="outlined">
        <Link to="/profile" className="btn">Profile .. Konfiguration</Link>
      </Button>
      {/* <Button variant="outlined">
        <Link to="/grid" className="btn">Grid .. Anzeige im Gridstyle</Link>
      </Button> */}
      <Button variant="outlined">
        <Link to="/gecko" className="btn">Crypto</Link>
      </Button>
      <Button variant="outlined">
        <Link to="/defi" className="btn">Defi (Decentralized Finance)</Link>
      </Button>

      <Button variant="outlined">
        <Link to="/temp" className="btn">Temp Tests</Link>
      </Button>
     
    </ButtonGroup>
 
  </>);
}

export default Home;
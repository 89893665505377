import { db } from "./db";
import { Box, Grid, Button, Select ,TextField, MenuItem, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';

export default function ViewDayGrid (props) {

  const handleUpdate = async (id) => {
    const row = await db.timetable
        .where("id")
        .equals(id)
        .toArray()
        //console.log(row)
    await db.timetable.delete(id)
  }

  const handleStd = async (event) => {
    const id = parseInt((event.target.id).split('_')[1])
    //console.log(id)
    const std = event.target.value
      
    try { 
      await db.timetable.update(id, {std: std})
      //console.log('update success')
    } catch (error) {
      //console.log(error)
    }   
  }

  const handleKat = async (event) => {
    const id = parseInt((event.target.name).split('_')[1])
    //console.log('ID:'+id)
    const kat = event.target.value
  
    try { 
      await db.timetable.update(id, {kat: kat})
      //console.log('update success')
    }
    catch (error) {console.log(error)}
  }

  //https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  const day = new Date(props.data.datum).toLocaleDateString('de-DE', {day:'2-digit'})
  const wt = new Date(props.data.datum).toLocaleDateString('de-DE', {weekday:'short'})
  const id = props.data.id

  return ( <>
    <Grid container spacing={0} className={"k-"+props.data.kat}
    sx={{mb:0.2}}>
        <Grid item xs={2}>
          <Box sx={{p:1}}>
            {wt.toUpperCase()}
          </Box>
          <Box sx={{ml:1,mt:-3,typography: 'h4',textShadow: 'white 2px 2px', color: 'black'}}>
           {day}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ p:1 }}>
          <FormControl fullWidth>
            <Select variant="standard"
                name={'k_'+id}
                value={props.data.kat}
                onChange={(e)=>{handleKat(e)}} 
                sx = {{ height:'45px' }} >
                <MenuItem value="we">SA/SO</MenuItem>
                <MenuItem value="o">Office</MenuItem>
                <MenuItem value="h">Homeoffice</MenuItem>
                <MenuItem value="u">Urlaub</MenuItem>
                <MenuItem value="za">Zeitausgleich</MenuItem>
                <MenuItem value="ft">Feiertag</MenuItem>
                <MenuItem value="k">Krankenstand</MenuItem>
            </Select>
            </FormControl>
            </Box>
        </Grid>
        { props.data.kat === 'o' || props.data.kat === 'h' || props.data.kat === 'k' ? 
          <Grid item xs={3}>
            <Box sx={{ p:1,}}>
              <TextField className="textField"
                  hiddenLabel
                  variant="filled" size="small"
                  id={'std_'+id} 
                  value={props.data.std}
                  onChange={event => handleStd(event)}
                />
            </Box>
            </Grid>  
            : <Grid item xs={3}></Grid>
          }
          <Grid item xs={2}>
            <Box display="flex" justifyContent="end">
              <Button variant="default">
                <DeleteIcon sx={{ fontSize: 50 }}
                  value={id}
                  onClick={()=>{handleUpdate(id)}}/>
              </Button>
            </Box>
          </Grid>
      </Grid>
  </> );
}
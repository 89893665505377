import React,{ useState }  from 'react';
import { db } from "../components/db";
import Import from '../components/Import';
import Crypto from '../components/Crypto';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

function Profile () {
  const [config,setConfig] = useState(null)
  const [name,setName] = useState('')
  const [email,setEmail] = useState('')
  const [file, setFile] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault()
    setConfig({'name':name,'email':email})
    console.log(config)
  };

  const handleBackup = async () => {
      const respData = await db.timetable
      .where("monat").between(0, 12)
      .sortBy('datum')
      downloadFile(respData)
  };

  const handleLoad = e => {
    const jsonFile = e.target.files[0]
      const fileReader = new FileReader()
      fileReader.readAsText(jsonFile, "UTF-8")
      fileReader.onload = e => {
        setFile(e.target.result)
      }
  }

  const handleRestore = () => {
    db.timetable.clear()
    const data = JSON.parse(file)
    try {
      db.timetable.bulkAdd(data)
      .then(console.log('daten zurück gesichert'),data) 
    } catch (error) {
      console.log(error)
    }
  }

  const downloadFile = async (data) => {
    const fileName = "backup-homeoffice-db";
    const json = JSON.stringify(data);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (<>
   
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Backup & Restore</Typography>
        </AccordionSummary>
        <AccordionDetails>
            Lokale DB exportieren/importieren<br></br>
            <button className='btn btn-outline-primary btn-sm'
              value='backup'
              onClick={(e)=>{handleBackup(e.target.value)} }>BACKUP FILE
            </button>
            <label className="btn btn-outline-primary btn-sm mx-1">
                <input onChange={handleLoad} id="restore" type="file" className="d-none" />
                UPLOAD FILE
            </label>

            {file ? (<><br></br><Button onClick={handleRestore} className="mt-1 ">RESTORE</Button> {file} </>) : <></>}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Feiertage laden und importieren</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Import />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Settings, Account & Config</Typography>
        </AccordionSummary>
        <AccordionDetails>

        Ratio: {process.env.REACT_APP_RATIO}<br/>
        Soll: {process.env.REACT_APP_SOLL}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="config">

            <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                <Form.Control type="text" placeholder="Name" 
                  onChange={(e) => setName(e.target.value)}
                  value={name}/>
            </FloatingLabel>
        
            <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
              <Form.Control type="email" placeholder="Enter email" 
                onChange={(e) => setEmail(e.target.value)}
                value={email}/> 
            </FloatingLabel>

            </Form.Group>

            <br></br>
      
      { name ? <>{name}</> : <></>}

      </Form>
       
        <Button variant="outline-secondary" type="submit">
          Submit
        </Button>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          Crypto - Tools
        </AccordionSummary>
        <AccordionDetails>
          <Crypto />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          Version
        </AccordionSummary>
        <AccordionDetails>
        name {process.env.REACT_APP_NAME} version {process.env.REACT_APP_VERSION}
        </AccordionDetails>
      </Accordion>
    
  </>
  );
}

export default Profile;
import { db } from "./db";
import DeleteIcon from '@mui/icons-material/DeleteForever';

import { Box, Grid } from '@mui/material';

function ViewDay (props) {

   const handleUpdate = async (id) => {
    const row = await db.timetable
        .where("id")
        .equals(parseInt(id))
        .toArray()
        console.log(row)
    await db.timetable.delete(id)   
    await (console.log('gelöscht')) 
  }

  const handleStd = async (event) => {
    const id = parseInt(event.target.id)
    const std = event.target.value
      
    try { 
      await db.timetable.update(id, {std: std})
      console.log('update success')
    } catch (error) {
      console.log(error)
    }   
  }

  const handleKat = async (event) => {
    const id = parseInt(event.target.id)
    const kat = event.target.value
  
    try { 
      await db.timetable.update(id, {kat: kat})
      console.log('update success')
    }
    catch (error) {console.log(error)}
  }

  const day = new Date(props.data.datum).toLocaleDateString('de-DE', {day:'2-digit'})
  const wt = new Date(props.data.datum).toLocaleDateString('de-DE', {weekday:'short'})
  const id = props.data.id

  return ( <>
    <Grid container className={"mt-1 card k-"+props.data.kat}>
      <Grid item xs={2}>
        <Box className="ms-1">{wt}</Box>
        <Box className="ms-1">{day}</Box>
      </Grid>
      <Grid item xs={6} className={"k-"+props.data.kat}>
        <select
            id={id}
            value={props.data.kat}
            label="Kategorie"
            onChange={(e)=>{handleKat(e)}}
            className={"badge k-"+props.data.kat}>
            <option value="we">SA/SO</option>
            <option value="o" name="Office">Office</option>
            <option value="h">Homeoffice</option>
            <option value="u">Urlaub</option>
            <option value="za">Zeitausgleich</option>
            <option value="ft">Feiertag</option>
            <option value="k">Krankenstand</option>
        </select> 
      </Grid>
      { props.data.kat === 'o' || props.data.kat === 'h'|| props.data.kat === 'k' ? 
        <Grid item xs={2}>
        <input
          style={{ textAlign: 'right'}}
          id={id}
          value={props.data.std}
          onChange={(e)=>{handleStd(e)}}
          className="me-auto form-control form-control-sm"
          type="Number"
          />
        </Grid>
        : <Grid item xs={2} ></Grid> }
      <Grid item xs={2}>
        <Box
          sx={{
            textAlign: "center"
          }}>
          <DeleteIcon
            value={id}
            onClick={()=>{handleUpdate(id)}}>
          </DeleteIcon>
        </Box>
      </Grid>
    </Grid>
  </> );
}

export default ViewDay ;
import React, {useState } from 'react';
import ViewDay from './ViewDayGrid';

import Stack from 'react-bootstrap/Stack';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ProgressBar from 'react-bootstrap/ProgressBar';

import { useLiveQuery } from "dexie-react-hooks";
import { db } from "./db";

export default function AZGrid (props) {
  const [selMonat, setSelMonat] = useState(props.monat);
  const [stats, setStats] = useState({});

  const handleSelMonat = (event) => {
    setSelMonat (event.target.value)
  }

  const liste = useLiveQuery(async () => {
    const data = await db.timetable
      .where("monat")
      .equals(parseInt(selMonat))
      .sortBy('datum')
      // console.log(data)
      // Statistik  
      
      let s_o = 0;let a_o=0;
      let s_h = 0;let a_h=0;
      let a_ft=0;let a_k=0;let a_u=0;let a_za=0;let a_we=0;
      data.forEach(e => {
        switch (e.kat) {
          case "o": s_o+=Number(e.std); a_o +=1;
            break;
          case "h": s_h+=Number(e.std); a_h +=1; 
            break;
          case "u": a_u +=1
            break;  
          case "ft": a_ft +=1
            break; 
          case "za": a_za +=1
            break; 
          case "k": a_k +=1
            break;
          case "we": a_we +=1
            break; 
          default:
            break;
        }
        
      });
      //Berechnungen
      const ratio = process.env.REACT_APP_RATIO
      const soll = process.env.REACT_APP_SOLL

      const summe_o = s_o + (a_o*7.7); //console.log(summe_o)
      const abzuege = ((a_ft*7.7) + (a_u*7.7) + (a_za*7.7) + (a_k*7.7))*ratio; //console.log('Abzüge:'+abzuege)
      const soll_o = soll*ratio - abzuege; //console.log('Soll:'+soll_o)
      const diff = summe_o - soll_o; //console.log('Diff:'+diff)
      let erfuellt = 'red'
      if (diff>0){ erfuellt='green' } else { erfuellt='red'}; //console.log(erfuellt)

      await setStats({"s_o":s_o,"a_o":a_o,"s_h":s_h,"a_h":a_h,"a_u":a_u,"a_ft":a_ft,"a_k":a_k,"a_za":a_za,"a_we":a_we,
      "summe_o":summe_o, "soll_o":soll_o,"diff":diff,"erfuellt":erfuellt,
      "soll":soll, "abzuege":abzuege})
    return data
  },[selMonat]) //[selMonat]..wie bei useEffect

  return (<>

<Grid container spacing={0} sx={{ mt: 10 }}>
  <Grid>
    <Stack direction="horizontal" gap={1}>
      <div className={"btn border px-1 "+stats.erfuellt}><b>{ stats.diff && <>{stats.diff.toFixed(2)}</>}</b></div>
      <div className="btn border k-o px-1"> { stats.summe_o && <>{stats.summe_o.toFixed(2)}</>}</div>
      <div className="btn border k-o px-1">{ stats.s_o && <>{stats.s_o.toFixed(2)}</>}</div>
      <div className="btn border k-o px-1">{ stats.a_o && <>{stats.a_o}</>}</div>
      <div className="btn border k-h px-1">{ stats.a_h && <>{stats.a_h}</>}</div>
      <div className="btn border k-h px-1">{ stats.s_h && <>{stats.s_h.toFixed(2)}</>}</div>
    </Stack>
  </Grid>
</Grid>
<Grid container spacing={0}>
  <Grid sx={{ mt: 2 }}>
    <FormControl fullWidth>
    <InputLabel>Monat</InputLabel>
      <Select value={selMonat} name='selMonat' onChange={handleSelMonat} size="small"
      sx={{ minWidth: 180 }}>
        <MenuItem value="0" label="Jänner">Jänner</MenuItem>
        <MenuItem value="1" name="Februar">Februar</MenuItem>
        <MenuItem value="2">März</MenuItem>
        <MenuItem value="3">April</MenuItem>
        <MenuItem value="4">Mai</MenuItem>
        <MenuItem value="5">Juni</MenuItem>
        <MenuItem value="6">Juli</MenuItem>
        <MenuItem value="7">August</MenuItem>
        <MenuItem value="8">September</MenuItem>
        <MenuItem value="9">Oktober</MenuItem>
        <MenuItem value="10">November</MenuItem>
        <MenuItem value="11">Dezember</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Stack direction="horizontal" gap={1} className="mt-2">
      <div className="mx-1"></div>
      <div tooltip="Zeitausgleich" className="btn border k-za px-1 text-light">{ stats.a_za && <>{stats.a_za}</>}</div>
      <div title="Feiertage" className="btn border k-ft px-1">{ stats.a_ft && <>{stats.a_ft}</>}</div>
      <div title="Urlaub" className="btn border k-u px-1 text-light">{ stats.a_u && <>{stats.a_u}</>}</div>
      <div title="Krank" className="btn border k-k px-1 text-light">{ stats.a_k && <>{stats.a_k}</>}</div>

      <div style={{ "width": "210px" }}>
        <ProgressBar now={ (stats.summe_o / stats.soll_o)*100 } 
          striped variant="success" label={`${((stats.summe_o / stats.soll_o)*100).toFixed(0)} %`}/>
      </div>
    </Stack>
  </Grid>

  <Grid sx={{ mt: 2, mb:5 }}>
    { liste?.map(row =>
    <ViewDay data={row} key={row.id}/>
    )} 
  </Grid>
 
  </>)  
}
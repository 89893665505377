import React, { useState } from 'react';
import sha256 from 'crypto-js/sha256';
import { TextField } from "@mui/material";

function Crypto () {
    const [pw, setPw] = useState({});
    const handlePhrase = (e) => {
        const pw = e.target.value
        console.log(pw)
        const cPw = sha256(pw);
        console.log(cPw)
        setPw(cPw)
    }

    return (<>
        <TextField onChange={handlePhrase} id="pw-phrase" label="PW Phrase" variant="standard" />
        {pw?<><h6 className="text-success">sha256 Hash</h6><p> {
            pw.words?.map(word => <h6>{word}</h6>)
        }</p></>:<></>}
    </>);
}

export default Crypto;
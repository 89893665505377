import React,{useEffect, useState} from 'react';
import axios from 'axios';

import {Row,Col} from 'react-bootstrap';
import {Box,Grid,Chip,ToggleButton,ToggleButtonGroup,List,ListItem} from '@mui/material';

import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';

export default function Defi () {
  const size = 500;
  const baseUrl = 'https://ocean.defichain.com/v0/mainnet';
  //const baseUrl = 'https://www.wencom.net/api3/defi';
  const [stats,setStats] = useState(null);
  const [poolpairs,setPoolpairs] = useState(null);
  const [prices,setPrices] = useState(null);
  const [alle,setAlle] = useState(null);

useEffect(() => {
    getData()
},[]);

const getData = () => {
  axios.get(baseUrl + '/stats')
  .then((response)=>{
    setStats(response.data.data)
    //console.log('Stats',response.data.data)
  })
  axios.get(baseUrl + '/poolpairs?size='+size)
    .then((response)=>{
      setPoolpairs(response.data.data)
      //console.log('Poolpairs',response.data)
  })
  axios.get(baseUrl + '/prices?size='+size)
    .then((response)=>{
      setPrices(response.data.data)
      //console.log('Prices:',response.data)
  })
}

  const merge = (pp,p) => {
    const result = [];
 
    for (let i = 0; i < pp.length; i++) {
      for (let j = 0; j < p.length; j++) {
        if ( (pp[i].symbol).replace('-DUSD','-USD') == p[j].id) {
          result.push({ ...pp[i], ...p[j] });
        }
      }
    }
    //console.log(result)
    setAlle(result)
  }

return(<>
  <Grid>
    { stats &&
    <Row >
      <Col>
        <ToggleButtonGroup
          color="primary"
          exclusive
          aria-label="Platform"
        >
          <ToggleButton onClick={()=>{merge(poolpairs,prices)}} value="prices">{(stats.price.usd).toFixed(3)}<PriceCheckIcon color="secondary"/></ToggleButton>
          <ToggleButton value="oracle"><SatelliteAltIcon color="primary"/> Oracle</ToggleButton>
        </ToggleButtonGroup>
      </Col>

    </Row>
    }

  <Row>
    <Col>
    <List>  
    { alle?.map((row,i)=>{
      //console.log(row.id)
      let dexPrice = parseFloat(row.priceRatio.ba).toFixed(2)
      let oraclePrice = parseFloat(row.price.aggregated.amount).toFixed(2)
      let dif = (((dexPrice - oraclePrice)/oraclePrice)*100).toFixed(1)

        return (
        <ListItem key={row.id}>
          <Box p={1}>
            {row.price.token}
          </Box>
          <Box p={1} color="primary.contrastText" bgcolor="secondary.main">
            {dexPrice}
          </Box>
          <Box p={1} color="primary.contrastText" bgcolor="primary.main">
            {oraclePrice}
          </Box>
          <Box p={1} color="primary.contrastText" bgcolor={dif<0?'warning.main':'success.main'}>
            {dif}%
          </Box>
        </ListItem>
        )
      })           
    }
    </List>
    </Col>
  </Row>

  </Grid> 
 </>)
}
import React,{ useState,useEffect  }  from 'react';
import { timestamp, feiertage } from './utils';
import { db } from './db';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function Import () {
  
  const [inputFeiertage,setInputFeiertage] = useState(null)


const saveImport = (e) => {
  e.preventDefault()
  try {
    db.timetable.bulkAdd(inputFeiertage)
    .then(console.log('save'),inputFeiertage)
  } catch (error) {
    console.log(error)
  }
}

const viewImport = (e) => {
  e.preventDefault()
  let ab = []
  feiertage.datumArray.map((datum) => (
    ab.push (
      {'datum':datum, 'id': timestamp(datum), 'timestamp':timestamp(datum), 'kat':'ft', 'monat':new Date (datum).getMonth()}
    )
  ))
  console.log(ab)
  setInputFeiertage(ab)
}

const downloadFile = async () => {
  const fileName = "feiertage";
  const json = JSON.stringify(inputFeiertage);
  const blob = new Blob([json],{type:'application/json'});
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

return (
  <>
  <Button onClick={viewImport} variant="outlined" >
    Zeige Feiertage</Button>
    <Stack direction='column' mt={1}> 
    { inputFeiertage?.map(row => 
      <Chip variant="outlined" 
        sx={{ boxShadow: 3, width: 150, m: 0.5 }}
        label={row.datum} 
        key={row.id} /> )}
    </Stack>
    { inputFeiertage ? (<>
      <Button onClick={saveImport} variant="outlined">Speichere Feiertage</Button>
      <Button onClick={downloadFile} variant="outlined" className="mx-1">Download Feiertage als Json</Button>
    </>
    ):(<></>)}
  </>)
};
import React,{ useState, useEffect }  from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import ListGroup from 'react-bootstrap/ListGroup';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Icons
import CachedIcon from '@mui/icons-material/Cached';

ReactFC.fcRoot(FusionCharts, charts, FusionTheme);

function Gecko () {
    const [liste,setListe] = useState(null)
    const [chartName,setChartName] = useState();
    const [chartLogo,setChartLogo] = useState();
    const [chartData,setChartData] = useState();
    const [coin,setCoin] = useState({
        id:'bitcoin',
        name: 'Bitcoin',
        image:'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579'
        });
    const [days,setDays] = useState(30);
    const [interval,setInterval] = useState('daily'); //daily,hourly

    const tickers = ['bitcoin','ethereum','binancecoin','cardano','polkadot','avalanche-2','aave','cosmos','curve-dao-token','osmosis','gmx','matic-network','1inch','uniswap','sushi','maker','crypto-com-chain','grizzly-honey','defichain','defi-franc-moneta'];
    const baseCoinGecko = 'https://api.coingecko.com/api/v3';

    const chartConfigs = {
        type: "line",
        width: "100%",
        height: "300",
        dataFormat: "json", // Data type
        dataSource: {
            // Chart Configuration
            chart: {
                logoURL: chartLogo,
                logoAlpha: "20",
                logoScale: "80",
                logoPosition: "CC",
                caption: chartName,
                drawAnchors: 0,
                setAdaptiveYMin: 1,
                //subCaption: 'Subtitle',
                //xAxisName: "Time",
                //yAxisName: "USD",
                //numberSuffix: "K",
                theme: "fusion"
                },
            data: chartData,
        }
    };

    FusionCharts.options.license({
        key: "bj1A1hwA-13F6B1H5D1I2C5A3A3D4C4B4A3yhbA5C5wsC-9gpD1A4kC-13nE2B4G1G3C2A5D5C4E3C2D4D2H1H3C10D7yf1g1D3E2D2pB6C1E3edsC1G4B1A-21D-16nB1A2B4mwuB14B1D8E3D5G2D4D1B2A16A7jB-13C-11E3A3OA1ptkE2B2G2ziF1BD6C8ggpG4E1C3C3A2B2C2E6D1B1F4I4B10D5n==",
        creditLabel: false
    });

    useEffect(() => {
       apiCoins()
       Grafik(coin)
       console.log(coin.symbol, ' ',days,' ',interval)
    },[coin,days,interval]);

    const apiCoins = () => {
        try {
            let url = baseCoinGecko+'/coins/markets?vs_currency=usd&order=market_cap_desc'
            url += '&ids='+tickers.join()
            axios.get(url).then((resp) => {
                console.log(resp);
                setListe(resp);
            })
        } catch (error) {
            console.log(error)
        }
    }

  const Grafik = (coin) => {
    //console.log(coin)
    setCoin(coin)
    setChartName(coin.name)
    setChartLogo(coin.image)
    try {
        let url = baseCoinGecko+`/coins/${coin.id}/market_chart?vs_currency=usd&days=${days}&interval=${interval}`
        axios.get(url).then((array) => {
            //console.log(array.data.prices);
            return array.data.prices
            //setChartData(array.data.prices);
        }).then((data)=>{
            let json = [];
            data.map((row) => {
                moment.locale()
                let label = ''
                if (interval==='daily') {
                    label = moment.unix(row[0]/1000).format('dd DD.MM')
                } else {
                    label = (moment.unix(row[0]/1000).format('dd HH')).toUpperCase()
                }
                json.push({label: label,value: row[1]})
                //console.log(`{label: "${label}",value: "${row[1]}"}`)
                return label
            })
            setChartData(json);
        })
    } catch (error) {
        console.log(error)
    }

  }
  const loadFilter = (filter) => {
    //console.log(filter)
    setDays(filter[0]);
    setInterval(filter[1]);
  }

  return (<>
    <Grid>
        <Chip onClick={apiCoins} icon={<CachedIcon />} variant="outlined"
            label={liste ? <> {moment(liste.headers.date).format('DD.MM HH:mm:ss')}</> : <></>}/>
        <Chip onClick={()=>loadFilter([1,'hourly'])} variant="outlined" label="1" />
        <Chip onClick={()=>loadFilter([3,'hourly'])} variant="outlined" label="3" />
        <Chip onClick={()=>loadFilter([7,'hourly'])} variant="outlined" label="W" />
        <Chip onClick={()=>loadFilter([30,'daily'])} variant="outlined" label="M" />
        <Chip onClick={()=>loadFilter([365,'daily'])} variant="outlined" label="Y" />
    </Grid>

    { liste &&
        <Grid sx={{ mt: 2, mb:5 }}>
            <ListGroup>

                { chartData &&
                <ListGroup.Item>
                    <ReactFC {...chartConfigs} />
                </ListGroup.Item>
                }
                { liste.data?.map(row =>

                    <ListGroup.Item key={row.id} style={
                        row.price_change_percentage_24h<0
                            ? {background: `rgba(255, 99, 71, 0.03)`}
                            : {background: `rgba(76, 175, 80, 0.06)`}
                    }>
                        <Container onClick={(e) => Grafik(row)}>
                        <Row>
                            <Col ><img src={row.image} height="30px" alt={row.id}/></Col>
                            <Col align="start">{ (row.symbol).toUpperCase() }</Col>
                            <Col align="end">{ (row.current_price).toFixed(2) }</Col>
                            <Col align="end" style={
                                row.price_change_percentage_24h<0 ? {color:'red'} : {color:'green'}
                            }>{
                            (row.price_change_percentage_24h).toFixed(1)
                            }%</Col>
                        </Row>
                        </Container>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </Grid>
    }

  </>);
}

export default Gecko;

import {useState} from 'react';
import { Link } from 'react-router-dom';

import { AppBar, Toolbar, Container,
  BottomNavigation, BottomNavigationAction,Button,
Menu,MenuItem } from '@mui/material';

import AZIcon from '@mui/icons-material/Restore';
import HomeIcon from '@mui/icons-material/Cottage';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import ToolsIcon from '@mui/icons-material/Construction';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

const Navigation = () => {
  const [value, setValue] = useState(0);

  const handleChange = (value) => {
    setValue(value)
    console.log('change navigation:',value)
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    
    <AppBar sx={{ bgcolor: "white" }}>
      <Container>
        <Toolbar>
      
      <Menu
        id="home-menu"
        aria-labelledby="home-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose} component={Link} to="/profile">Profile</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/gecko">Crypto</MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/defi">Defi</MenuItem>
      </Menu>

        <BottomNavigation showLabels
          sx={{ width: '100%'}}
          value={value}
          onChange={(e,value)=> handleChange(value)}>

            <BottomNavigationAction label="Home" 
              component={Link} 
              to="/"
              value="home"
              icon={<HomeIcon />} 

              id="home-positioned-button"
              aria-controls={open ? 'home-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              />

            <BottomNavigationAction label="AZ" 
              component={Link} 
              to="/arbeitszeit"
              value="arbeitszeit"
              icon={<AZIcon />} />

            <BottomNavigationAction label="Tools"  
              component={Link} 
              to="/profile"
              value="profile"
              icon={<ToolsIcon />} />
            
            <BottomNavigationAction label="Crypto" 
              component={Link} 
              to="/gecko"
              value="gecko"
              icon={<CurrencyBitcoinIcon />} />
          
          <BottomNavigationAction label="Defi" 
              component={Link} 
              to="/defi"
              value="defi"
              icon={<ViewInArIcon />} />

          </BottomNavigation>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navigation;

import { db } from './db';
import { useLiveQuery } from "dexie-react-hooks";

export function timestamp (datum) {
  
  let germanDatum = (datum).toLocaleString('de-DE');
  //console.log(germanDatum)

  let event = new Date(germanDatum);
  const timestamp = event.getTime();
  return timestamp
}

export const config = {
  "hoRatio": 0.4,
  "hoSoll": 166
}

export const feiertage = {
  "datumObjekt": {
      "Neujahr": "Samstag, 1. Jänner 2023",
      "Heilige Drei Könige": "Donnerstag, 6. Jänner 2022",
      "Ostermontag": "Montag, 18. April 2022",
      "Staatsfeiertag": "Sonntag, 1. Mai 2022",
      "Christi Himmelfahrt": "Donnerstag, 26. Mai 2022",
      "Pfingstmontag": "Montag, 6. Juni 2022",
      "Fronleichnam": "Donnerstag, 16. Juni 2022",
      "Mariä Himmelfahrt": "Montag, 15. August 2022",
      "Nationalfeiertag": "Mittwoch, 26. Oktober 2022",
      "Allerheiligen": "Dienstag, 1. November 2022",
      "Mariä Empfängnis": "Donnerstag, 8. Dezember 2022",
      "Christtag": "Sonntag, 25. Dezember 2022",
      "Stefanitag": "Montag, 26. Dezember 2022"
  },
  "datumArray": [
      "2023-01-01","2023-01-06","2023-04-10","2023-05-01","2023-05-18","2023-05-29","2023-06-08",
      "2023-08-15","2023-10-26","2023-11-01","2023-12-08","2023-12-25","2023-12-26"
  ]
}

export const Data = (props) => {
  const data = useLiveQuery(
    () => db.timetable.where("monat").equals(parseInt(props.monat)).sortBy("id"),
    [] // because selMonat affects query!
  );
  console.log(data)
  return (<>
  Monat: {props.monat}<br></br>
  Datum: {data[0].datum}
  </>)
}

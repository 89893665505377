import React, {useState} from 'react';

import { db } from "./db";
import { useLiveQuery } from "dexie-react-hooks";
import ViewDay from './AZ_ViewDay';

import { Grid, Card, Box, Chip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function Arbeitszeit (props) {

  const style = { 
    o: { color: 'white', borderColor: 'pink', backgroundColor: 'rgb(237, 177, 229)', fontSize: 'x-small'},
    h: { color: 'black', borderColor: 'grey', backgroundColor: 'rgb(214, 209, 213)', fontSize: 'x-small'},
    za: { color: 'white', borderColor: 'darkgreen', backgroundColor: 'rgb(33, 131, 77)', fontSize: 'x-small'},
    u: { color: 'white', borderColor: 'darkgreen', backgroundColor: 'rgb(8, 69, 35)', fontSize: 'x-small'},
    ft: { color: 'white', borderColor: 'darkgreen', backgroundColor: 'rgb(46, 187, 110)', fontSize: 'x-small'},
    k: { color: 'white', borderColor: 'red', backgroundColor: 'rgb(235, 107, 107)', fontSize: 'x-small'},
    g: { color: 'white', borderColor: 'darkgreen', backgroundColor: 'rgb(126, 218, 146)'}
  }

  const [selMonat, setSelMonat] = useState(props.monat);
  const [stats, setStats] = useState({});

  const monatFull = useLiveQuery(async () => {
    const data = await db.timetable
      .where("monat")
      .equals(parseInt(selMonat))
      .sortBy('datum')
      // console.log(data)
   
      let s_o = 0;let a_o=0;
      let s_h = 0;let a_h=0;
      let a_ft=0;let a_k=0;let a_u=0;let a_za=0;let a_we=0;

      data.forEach(e => {
        switch (e.kat) {
          case "o": s_o+=Number(e.std); a_o +=1;
            break;
          case "h": s_h+=Number(e.std); a_h +=1; 
            break;
          case "u": a_u +=1
            break;  
          case "ft": a_ft +=1
            break; 
          case "za": a_za +=1
            break; 
          case "k": a_k +=1
            break;
          case "we": a_we +=1
            break; 
          default:
            break;
        }
      })

      //console.log('Summe Office:' + s_o.toFixed(2) )
      //console.log('Anzahl FT:' + a_ft )

      //Berechnungen
      const ratio = 0.4;//process.env.REACT_APP_RATIO
      const soll = 166.71;//process.env.REACT_APP_SOLL
      
      const summe_o = s_o + (a_o*7.7); //console.log(summe_o)
      const summe_h = s_h + (a_h*7.7);
      const abzuege = ((a_ft*7.7) + (a_u*7.7) + (a_za*7.7) + (a_k*7.7))*ratio; //console.log('Abzüge:'+abzuege)
      const soll_o = soll*ratio - abzuege; //console.log('Soll:'+soll_o)
      const diff = summe_o - soll_o; //console.log('Diff:'+diff)
      let erfuellt = 'red'
      if (diff>0){ erfuellt='green' } else { erfuellt='red'}; //console.log(erfuellt)

      await setStats({"s_o":s_o,"a_o":a_o,"s_h":s_h,"a_h":a_h,"a_u":a_u,"a_ft":a_ft,"a_k":a_k,"a_za":a_za,"a_we":a_we,
      "summe_o":summe_o, "summe_h":summe_h, "soll_o":soll_o,"diff":diff,"erfuellt":erfuellt})
      
    return data
  },[selMonat])
   
  const handleSelMonat = (event) => {
    console.log('monat: '+event.target.value)
    setSelMonat (event.target.value)
  }

return (<>

{ stats.s_o ? <>
  <Grid container spacing={0.2} sx={{ mb:2 }}>
    <Grid item xs={2} sx={{textAlign:'center'}} >
      <Card style={style.o} sx={{mb:0.5}}>{stats.summe_o.toFixed(1)}</Card>
      <Card style={style.h} sx={{mb:0.5}}>{stats.summe_h.toFixed(1)}</Card>
    </Grid>
    <Grid item xs={1} sx={{textAlign:'center'}} >
      <Card style={style.o} sx={{mb:0.5}}>{stats.a_o}</Card>
      <Card style={style.h} sx={{mb:0.5}}>{stats.a_h}</Card>
    </Grid>
    <Grid item xs={1} sx={{textAlign:"center"}}>
      <Card style={style.u} sx={{mb:0.5}}>{stats.a_u}</Card>
      <Card style={style.za} sx={{mb:0.5}}>{stats.a_za}</Card>
    </Grid>
    <Grid item xs={1} sx={{textAlign:"center"}}>
      <Card style={style.ft} sx={{mb:0.5}}>{stats.a_ft}</Card>
      <Card style={style.k} sx={{mb:0.5}}>{stats.a_k}</Card>
    </Grid>
    <Grid item xs={7} variant='outline'>
      <Box sx={{ 
        mb:0.5, 
        //textAlign: 'center',
        //width:`${(stats.summe_o/stats.soll_o)*100}%`,
        color: `${ stats.diff<0?'red':'green'}`,
        //backgroundColor: `${(stats.summe_o/stats.soll_o)>1?'rgb(2, 107, 107)':'rgb(235, 107, 107)'}`,
        fontSize: "x-small" }}
        >
        { stats.diff.toFixed(1) + "h ... " + ((stats.diff)*60).toFixed(0) + "min"}
      </Box>
      <Card sx={{ 
        width:`${(stats.summe_o/stats.soll_o)*100}%`,
        textAlign: 'center',
        color: 'white',
        backgroundColor: `${(stats.summe_o/stats.soll_o)>1?'rgb(2, 107, 107)':'rgb(235, 107, 107)'}`,
        fontSize: "x-small" }}
        >
        { ((stats.summe_o / stats.soll_o)*100).toFixed(1) + '%'}
      </Card>
     
    </Grid>
  </Grid>
  </>:<><Chip label="noch keine Statistik vorhanden!" color="warning" sx={{mb:2}} /></>
}

<FormControl fullWidth>
  <InputLabel>
    <CalendarIcon></CalendarIcon> Monat {parseInt(selMonat)+1}
  </InputLabel>
  <Select value={selMonat} onChange={handleSelMonat} size="small">
      <MenuItem value="0">Jänner</MenuItem>
      <MenuItem value="1">Februar</MenuItem>
      <MenuItem value="2">März</MenuItem>
      <MenuItem value="3">April</MenuItem>
      <MenuItem value="4">Mai</MenuItem>
      <MenuItem value="5">Juni</MenuItem>
      <MenuItem value="6">Juli</MenuItem>
      <MenuItem value="7">August</MenuItem>
      <MenuItem value="8">September</MenuItem>
      <MenuItem value="9">Oktober</MenuItem>
      <MenuItem value="10">November</MenuItem>
      <MenuItem value="11">Dezember</MenuItem>
  </Select>
</FormControl>

<Grid sx={{ mt: 2, mb:5 }}>
    { monatFull?.map(row =>
    <ViewDay data={row} key={row.id}/>
    )} 
</Grid>

</>)  
}
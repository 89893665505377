import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Navigation from './components/Navigation';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Gecko from './pages/Gecko';
import Defi from './pages/Defi';
import Arbeitszeit from './pages/Arbeitszeit';

import Temp from './pages/Temp';
import AZGrid from './components/AZGrid';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Container from '@mui/material/Container';

function App () {

  const [wd, setWd] = useState({});
  const [monat, setMonat] = useState(0);

  useEffect (() => {
    const heute = new Date();
    //console.log(config)
    setMonat(heute.getMonth());
    setWd({})
   }, [monat]);

  return (<>
    <Navigation/>
    <Container maxWidth="xl" sx={{ mt: 10, width: 1 }}>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/temp" element={<Temp/>}></Route> 
          <Route path="/grid" element={<AZGrid monat={monat} />}></Route> 
          <Route path="/profile" element={<Profile/>}></Route>
          <Route path="/gecko" element={<Gecko/>}></Route>
          <Route path="/defi" element={<Defi/>}></Route>
          <Route path="/arbeitszeit" element={<Arbeitszeit wd={wd}/>}></Route>
          <Route path="*" element={<Home />}></Route>
        </Routes>
    </Container>
    </>
  );
}

export default App;

import React,{useEffect, useState} from 'react';
//import axios from 'axios';

//import { DataGrid, GridToolbar, deDE } from '@mui/x-data-grid';

import Box from '@mui/material/Box';

export default function Temp () {
  //const [cont,setCont] = useState([]);

  useEffect(() => {
    console.log('run')
    // const config = {
    //   method: 'get',
    //   url: baseUrl,
    //   // headers: { 
    //   //   'X-Unfold-Lineups': 'true', 
    //   //   'X-Unfold-Goals': 'true', 
    //   // }
    // };
    
    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    //   const $ = cheerio
    //   cheerio.load(response.data)
    //   console.log($)
    //   setCont(response.data)
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

 },[]);

  return(<>
  
  <Box sx={{ height: 900, width: '100%' }}>
    <h1>Temp</h1>
  </Box>
  
 </>)
}
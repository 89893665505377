import React,{ useState,useRef }  from 'react';
import { db } from "../components/db";
import { timestamp } from "../components/utils";

import AZListe from '../components/AZ_Liste';

import {Alert, Grid, Button, FormControl, InputLabel, TextField, MenuItem, Select} from '@mui/material';

import SaveIcon from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForever';

export default function Arbeitszeit ({wd}) {
  
  const refStd = useRef();
  const heute = new Date();

  const aktMonat = heute.getMonth();
  const [output, setOutput] = useState('');
  new Date(wd.datum).getMonth();

  const [selMonat, setSelMonat] = useState(aktMonat);
  const [selDatum, setSelDatum] = useState(heute.toISOString().split('T')[0]);
  //console.log(selDatum)
  wd.datum = selDatum
  const [selKat, setSelKat] = useState('');
  const [selStd, setSelStd] = useState('');

  const handleChangeDatum = (value) => {
    setSelDatum(value);
    wd.datum = value;
    wd.monat = new Date(wd.datum).getMonth();
    //console.log(wd.monat)
    setOutput(`Sel Datum: ${wd.datum}`);
  }

  const handleSelKat = (event) => {
    //console.log(event.target.value)
    wd.kat = event.target.value;
    setSelKat(event.target.value);
    //console.log(wd.kat)
    if (wd.kat==='ft'||wd.kat==='k'||wd.kat==='za'||wd.kat==='u'||wd.kat==='we') { setSelStd('') }
    setOutput(`sel Kat: ${wd.kat}`)
  }

  const handleChangeStd = (value) => {
    setSelStd(value);
    wd.std = value
    setOutput(`sel Std: ${wd.std}`)
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    wd.uuid = crypto.randomUUID()
    wd.id = timestamp(wd.datum)
    wd.monat = new Date(wd.datum).getMonth();
    console.log(wd)
    try {
      if (!wd.datum) {
        setOutput('Datum ausfüllen!')
      } else { 
        await db.timetable.add(wd)
        setOutput('Abgespeichert!')}
    } catch (error) {
      //console.log(error)
      setOutput(`Error: ${error.inner}`)
    }
  }
  
  const handleDelete = async (e) => {
    //console.log(e)
    await db.timetable.delete(timestamp(selDatum))   
    await (console.log(timestamp(selDatum),'gelöscht')) 
  }

  return (<>
    <Grid container spacing={1} >
    <Grid item xs={12}></Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
        <TextField 
          id="datum" label="Datum" variant="outlined" size="small"
          type="date"
          //ref={selDatumRef}
          value={selDatum}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={event => handleChangeDatum(event.target.value)}
        />
        </FormControl>
      </Grid>   
       
      <Grid item xs={6}>
        <FormControl fullWidth>
        <InputLabel>Kategorie</InputLabel>
          <Select size="small"
            value={selKat}
            label="Kategorie"
            onChange={handleSelKat}>
              <MenuItem value="">
                <em>Kategorie</em>
              </MenuItem>
              <MenuItem value="we">SA/SO</MenuItem>
              <MenuItem value="o">Office</MenuItem>
              <MenuItem value="h">Homeoffice</MenuItem>
              <MenuItem value="u">Urlaub</MenuItem>
              <MenuItem value="za">Zeitausgleich</MenuItem>
              <MenuItem value="ft">Feiertag</MenuItem>
              <MenuItem value="k">Krankenstand</MenuItem>
          </Select>
          </FormControl>
      </Grid>
      
      <Grid item xs={6}>
        <FormControl fullWidth>
        <TextField 
            id="std" label="Std" variant="outlined" size="small"
            value={selStd}
            onChange={event => handleChangeStd(event.target.value)}
            ref={refStd}
            type="number"
          />
          </FormControl> 
      </Grid>

      <Grid item xs={3}>
        <FormControl fullWidth>
          <Button variant="outlined" value="test" 
            onClick={handleDelete}
            ><DeleteIcon/></Button>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Button variant="outlined" value="Submit" 
            type="submit"
            onClick={handleSubmit}
            ><SaveIcon/></Button>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
      { output && <Alert show={output.toString()} severity="info">{output}</Alert> }
      </Grid>
        
      <Grid item xs={12}>
        <AZListe monat={selMonat}/>
      </Grid>
    
    </Grid>
  </>
)};